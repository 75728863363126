<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable"
  >
    <template #header>
      <view-search-form
      keyword-placeholder="参数名、参数键"
        :queryInfo.sync="queryInfo"
        :tool-list="[ 'keyword']"
        :show-clear-btn="false"
        style="display: inline-block;"
        @on-search="renderTable(1)" />
      <div class="header-button fr">
        <el-button type="primary" size="mini" @click="showDialog()">
          添加参数
        </el-button>
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column prop="paramName" label="参数名" width="150" />
      <el-table-column prop="userType" label="参数类型" width="120" align="center">
        <template v-slot="{row}">
            <span v-for="(item, index) in paramsType" :key="index">
              <el-tag
                :type="item.tag"
                v-if="Number(row.paramType) === item.type"
              >{{ item.title }}</el-tag>
            </span>
        </template>
      </el-table-column>
      <el-table-column prop="paramKey" label="参数健" width="220" />
      <el-table-column label="参数值" min-width="150">
        <template v-slot="{row}">
          {{ row.paramValue.length > 163 ? row.paramValue.slice(0, 160) + '...' : row.paramValue}}
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" min-width="220" />
      <el-table-column label="操作" width="150" fixed="right">
        <template v-slot="{row}">
          <!--  可编辑的情况：
             1. 自己创建的数据；
             2. 开发人员 角色；
             3. 超级管理员 角色；
             -->
          <template
            v-if="row.createUserId===$store.state.login.userInfo.id||$store.state.login.isAdmin">
            <el-button type="primary" size="mini" @click="showDialog(row.id)">编辑</el-button>
            <el-button size="mini" type="danger" @click="del(deleteParams, row.id,`系统参数/字典-${row.paramName}`)">删除
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <add-params ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" @on-close="renderTable" />
  </table-view>
</template>

<script>
import { getParamsList, deleteParams } from '@/api/sysParams'
import addParams from './dialog/addParams.vue'
import tableView from '@/vue/mixins/table-view'
import { paramsType } from '@/views/pages/system/paramsList/dialog/options'

export default {
  name: 'params',
  components: {
    addParams
  },
  mixins: [tableView],
  data () {
    return {
      paramsType
    }
  },
  methods: {
    deleteParams, // 删除api
    // 获取用户列表
    renderTable (pageNum) {
      this.getTableData(getParamsList, pageNum)
    }
  }
}
</script>
