<template>
  <el-dialog
    class="addUser-dialog"
    :title="`${preTitle}系统参数`"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="cancel"
    width="480px"
  >
    <el-form rules="rules" ref="form" :model="formData" label-width="100px">
      <el-form-item label="参数名" prop="paramName">
        <el-input v-model.trim="formData.paramName" />
      </el-form-item>
      <el-form-item label="参数健" prop="paramKey">
        <el-input v-model.trim="formData.paramKey" />
      </el-form-item>
      <el-form-item label="参数值" prop="paramValue">
        <el-input type="textarea" rows="3" v-model.trim="formData.paramValue" />
      </el-form-item>
      <el-form-item label="参数类型" prop="paramType">
        <el-radio-group v-model="formData.paramType">
          <el-radio-button v-for="{title, type} in paramsType" :key="type" :label="type">{{ title }}</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save('系统参数',addParams)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addParams, getParamsById } from '@/api/sysParams'
import dialog from '@/vue/mixins/dialog'

export default {
  mixins: [dialog],
  props: {
    addData: {
      type: Object
    }
  },
  data() {
    return {
      formData: {
        id: null,
        paramKey: '', // 参数key
        paramValue: '', // 参数value
        paramName: '', // 参数name
        paramType: 2, // 1系统参数，2系统字典
        remarks: ''
      },
      paramsType: [{
        title: '系统参数',
        type: 1
      }, {
        title: '系统字典',
        type: 2
      }],
      rules: {
        paramKey: { required: true, message: '改线为必填', trigger: 'blur' },
        paramValue: { required: true, message: '改线为必填', trigger: 'blur' },
        paramName: { required: true, message: '改线为必填', trigger: 'blur' },
        paramType: { required: true, message: '改线为必填', trigger: 'blur' }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.id) {
        this.getDataById(this.id, getParamsById)
      }
    })
  },
  methods: {
    addParams // 保存api
  }
}
</script>
